<template>
  <div class="battery">
    <div class="title">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="设备编号">
          <el-input
            v-model="searchForm.number"
            clearable
            placeholder="请输入设备编号/电池二维码/通讯板编号"
            style="width:300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品型号">
          <el-select
            ref="searchFormProductNo"
            v-model="searchForm.productNo"
            clearable
            filterable
            placeholder="请选择产品型号"
          >
            <el-option
              v-for="opt in productList"
              :key="opt.productNo"
              :value="opt.productNo"
              :label="opt.productModel"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作状态">
          <el-select
            ref="searchFormWorkStatus"
            v-model="searchForm.workStatus"
            clearable
            placeholder="请选择工作状态"
          >
            <el-option
              v-for="opt in WORK_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="在线状态">
          <el-select
            ref="searchFormStatus"
            v-model="searchForm.status"
            clearable
            placeholder="请选择在线状态"
          >
            <el-option
              v-for="opt in STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="BMS类型">
          <el-select
            ref="searchFormBmsType"
            v-model="searchForm.bmsType"
            clearable
            placeholder="请选择BMS类型"
          >
            <el-option
              v-for="opt in BMS_BOARD_TYPE"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="激活状态">
          <el-select
            ref="searchFormActivityStatus"
            v-model="searchForm.activityStatus"
            clearable
            placeholder="请选择激活状态"
          >
            <el-option
              v-for="opt in ACTIVITY_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电池状态">
          <el-select
            ref="searchFormBatteryStatus"
            v-model="searchForm.batteryStatus"
            clearable
            placeholder="请选择电池状态"
          >
            <el-option
              v-for="opt in BATTERY_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="定位状态">
          <el-select
            ref="searchFormPositionStatus"
            v-model="searchForm.positionStatus"
            clearable
            placeholder="请选择定位状态"
          >
            <el-option
              v-for="opt in POSITION_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <div class="fr mb20">
        <el-button type="primary" @click="closeBattery">关闭放电开关</el-button>
        <el-button class="bkcyan bdcyan" type="primary" @click="goAdd" icon="el-icon-success">新增电池</el-button>
        <el-button class="bkeee bdeee black" type="primary" @click="passTest" icon="el-icon-success">
          测试通过
        </el-button>
        <el-button type="danger" @click="delNewBattery('', 'all')" icon="el-icon-success"
          >删除设备</el-button
        >
        <el-button class="bkyellow bdyellow" type="primary" @click="openProductNo" icon="el-icon-success">修改型号</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="list"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="deviceNo" min-width="210" label="设备编号">
          <template slot="header">
            <p>设备编号</p>
            <p>电池二维码</p>
            <p>通讯板编号</p>
          </template>
          <template slot-scope="{ row }">
            <p><span class="c9">设备编号:</span> <span class="blue">{{ row.deviceNo }}</span> </p>
            <p><span class="c9">电池二维码:</span> <span class="blue">{{ row.linkNo }}</span></p>
            <p><span class="c9">通讯板编号:</span> <span class="blue">{{ row.deviceId }}</span></p>
          </template>
        </el-table-column>
        <el-table-column prop="status"  label="激活状态">
          <template slot-scope="{ row }">
            <span :class="[{'bdblue':+row.activityStatus === 2},{'bkblue bk blue':row.activityStatus}]">
              {{ $changeObj(ACTIVITY_STATUS)[row.activityStatus] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="在线状态" >
          <template slot-scope="{ row }">
            <span :class="{'red':+row.status === 0}">
              {{ $changeObj(STATUS)[row.status] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="productModel" label="产品型号" min-width="150"/>
        <el-table-column prop="bmsType" label="BMS板">
          <template slot-scope="{ row }">
            <span :class="[{'bkorange orange bk':row.bmsType},{'bkorageDeep bdorange':+row.bmsType === 2}]">
              {{ $changeObj(BMS_BOARD_TYPE)[row.bmsType] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="workStatus" label="工作状态" >
          <template slot-scope="{ row }">
            {{ $changeObj(WORK_STATUS)[row.workStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="batteryStatus" label="电池状态" >
          <template slot-scope="{ row }">
            {{ $changeObj(BATTERY_STATUS)[row.batteryStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="publishStatus" label="发布状态">
          <template slot-scope="{ row }">
            {{ $changeObj(PUBLISH_STATUS)[row.publishStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="positionStatus" label="定位状态">
          <template slot-scope="{ row }">
            {{ $changeObj(POSITION_STATUS)[row.positionStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="soc" label="电池电量" >
          <template slot-scope="{ row }"> {{ row.soc || '0%' }} </template>
        </el-table-column>
        <el-table-column prop="createTime" label="导入时间" min-width="150"/>
        <el-table-column fixed="right" width="300" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="viewDetail(scope.row)"
            v-if="scope.row.activityStatus == 2"
              >详情</el-button
            >
            <el-button
              class="green bkgreenshallow bdgreen"
               size="mini"
              @click="getOneBattery(scope.row, scope.$index)"
              :loading="scope.row.loading"
              >更新数据</el-button
            >
            <el-button class="bdorange bkorange orange" type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button  size="mini" @click="delNewBattery(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="修改型号" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="产品型号">
          <el-select
            v-model="productNo"
            placeholder="请选择产品型号"
            clearable
            filterable
          >
            <el-option
              v-for="opt in productList"
              :key="opt.productNo"
              :value="opt.productNo"
              :label="opt.productModel"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveProduct">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getNewBatteryList,
  delNewBattery,
  passTest,
  saveProduct,
  getOneBattery
} from "@/api/facility";
import { getProductList,dischargeMosSwitch } from "@/api/product";
import {
  PRODUCT_TYPE,
  STATUS,
  WORK_STATUS,
  BMS_BOARD_TYPE,
  ACTIVITY_STATUS,
  POSITION_STATUS,
  PUBLISH_STATUS,
  BATTERY_STATUS
} from "@/js/constant";
import { timeToDate } from "@/utils/date-format";
export default {
  data() {
    return {
      searchForm: {
        number: "", // 设备编号
        productNo: "", // 产品型号
        status: "", // 工作状态
        workStatus: "", // 工作状态
        bmsType: "", // BMS类型
        activityStatus: "", // 激活状态
        batteryStatus: "", // 电池状态
        positionStatus: "" //定位状态
      },
      PRODUCT_TYPE,
      STATUS,
      WORK_STATUS,
      BMS_BOARD_TYPE,
      ACTIVITY_STATUS,
      BATTERY_STATUS,
      POSITION_STATUS,
      PUBLISH_STATUS,
      beginIndex: 1,
      pageSize: 20,
      total: 0,
      list: [],
      /**选择的设备 */
      selectList: [],
      /** 产品列表 */
      productList: [],
      // 弹出层
      dialogVisible: false,
      /** 产品型号 */
      productNo: "",
      loading: false
    }
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/addNewBattery') {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
    this.getNewBatteryList();
      this.getProductList();
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.searchFormProductNo.blur()
        _this.$refs.searchFormWorkStatus.blur()
        _this.$refs.searchFormStatus.blur()
        _this.$refs.searchFormBmsType.blur()
        _this.$refs.searchFormActivityStatus.blur()
        _this.$refs.searchFormBatteryStatus.blur()
        _this.$refs.searchFormPositionStatus.blur()
        _this.onSearch()
      }
    };
    this.getNewBatteryList();
    this.getProductList();
  },
  methods: {
    // 关闭放电开关
    closeBattery(){
      if(this.selectList.length === 0){
        this.$message.error('请勾选电池');
        return;
      }
       this.$confirm('是否确定关闭选中设备放电开关?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           this.dischargeMosSwitch();
        })
    },
    // 关闭按钮请求
    async dischargeMosSwitch(){
      let str = '';
      this.selectList.forEach(el=>{
         str +=  `${el.deviceNo},`;
      })
      try{
        const params = {
          numbers:str
        };
        const res = await dischargeMosSwitch(params);
        if(res.result == 1){
          this.$message({
            type: 'success',
            message: res.message
          });
        }else{
          this.$message({
            type: 'error',
            message: res.message
          });
        }

      }catch(e){
        console.log(e,'e');
      }
    },
    onSearch() {
      this.beginIndex = 1;
      this.getNewBatteryList();
    },
    async getNewBatteryList() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        beginIndex: page,
        pageSize: this.pageSize,
        troubleStatus: 1
      };
      const res = await getNewBatteryList(params);
      res.ldata.forEach(el => {
        if (el.createTime == "null") {
          el.createTime = null;
        }
        if (el.createTime && el.createTime != "null") {
          el.createTime = timeToDate(el.createTime);
        }
        el.loading = false;
      });
      this.list = res.ldata;
      this.total = +res.mdata.total;
    },
    /** 新增 */
    goAdd() {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/device/status/create' ).then((flag) => {
        if(flag) {
          this.$router.push({
            path:"/leaseholdMgmt/addNewBattery",
            query:{
              routerType: "new"
            }
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push({
          path:"/leaseholdMgmt/addNewBattery",
          query:{
            routerType: "new"
          }
        });
      })

    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getNewBatteryList();
    },
    /**删除 */
    delNewBattery(row, type) {
      if (type == "all" && !this.selectList.length) {
        this.$message.error("请选择设备");
        return;
      }
      this.$confirm("是否确定删除电池?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            haiLiDeviceNoList: JSON.stringify([row.deviceNo])
          };
          if (type == "all") {
            params.haiLiDeviceNoList = JSON.stringify(
              this.selectList.map(el => el.deviceNo)
            );
          }
          await delNewBattery(params);
          this.$message.success("删除成功");
          this.beginIndex = 1;
          this.getNewBatteryList();
        })
        .catch(() => {});
    },
    /** 选择 */
    handleSelectionChange(row) {
      this.selectList = row;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /** 获取产品 */
    async getProductList() {
      const params = {
        beginIndex: 0,
        pageSize: 500,
        productType: 1
      };
      const res = await getProductList(params);
      this.productList = res.ldata;
    },
    // 测试通过
    async passTest() {
      if (!this.selectList.length) {
        this.$message.error("请选择设备");
        return;
      }
      this.$confirm("是否确定测试通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            haiLiDeviceNoList: JSON.stringify(
              this.selectList.map(el => el.deviceNo)
            ),
            publishStatus: 2
          };
          await passTest(params);
          this.$message.success("测试成功");
          this.onSearch();
        })
        .catch(() => {});
    },
    /**打开弹窗 */
    openProductNo() {
      if (!this.selectList.length) {
        this.$message.error("请选择设备");
        return;
      }
      this.dialogVisible = true;
    },
    async saveProduct() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (!this.productNo) {
        this.$message.error("请选择产品型号");
        return;
      }
      const params = {
        productNo: this.productNo,
        haiLiDeviceNoList: JSON.stringify(
          this.selectList.map(el => el.deviceNo)
        )
      };
      try {
        await saveProduct(params);
        this.dialogVisible = false;
        this.loading = false;
        this.$message.success("修改成功");
        this.onSearch();
      } catch (error) {
        this.loading = false;
      }
    },
    /**编辑 */
    edit(row) {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/device/status/updateInfo' ).then((flag) => {
        if(flag) {
          this.$router.push({
            path: "/leaseholdMgmt/addNewBattery",
            query: {
              type: "edit",
              deviceNo: row.deviceNo,
              productNo: row.productNo,
              linkNo: row.linkNo,
              deviceId: row.deviceId,
              routerType: "new"
            }
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push({
          path: "/leaseholdMgmt/addNewBattery",
          query: {
            type: "edit",
            deviceNo: row.deviceNo,
            productNo: row.productNo,
            linkNo: row.linkNo,
            deviceId: row.deviceId,
            routerType: "new"
          }
        });
      })
    },
    /** 获取单个电池信息 */
    async getOneBattery(row, index) {
      if (this.loading) {
        return;
      }
      row.loading = true;
      const params = {
        haiLiDeviceNo: row.deviceNo
      };
      try {
        const res = await getOneBattery(params);
        const newRow = res.ldata[0];
        newRow.loading = false;
        newRow.createTime = timeToDate(newRow.createTime)
        this.$set(this.list, index, newRow);
        this.$message.success("更新成功");
      } catch (error) {
        row.loading = false;
      }
    },
    // 详情按钮
    viewDetail(row) {
      this.$router.push({
        path: "/leaseholdMgmt/batteryView",
        query: {
          deviceNo: row.deviceNo,
          back:this.$route.fullPath
        }
      });
    }
  },
  components: {}
};
</script>

<style scoped lang="less">
.battery {
  padding-bottom: 80px;
}
</style>
